<template>
  <div>
    <v-container >
      <DepartmentsList/>
    </v-container>
  </div>
</template>

<script>
import DepartmentsList from '../../../components/War/Department/DepartmentsList.vue';

export default {
  name: 'WarDepartments',
  components: {
    DepartmentsList,
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
  },
  methods: {
  },
};

</script>
