<template>
  <div>
    <CRUDCriteria
        :showSearch="false"
        :showReset="true"
      >
      <template v-slot:criteriaRows>
       <v-row>
        <v-col cols="4">
          <v-select
            id="war-department-criteria-type"
            :items="govFunction"
            label="By Function"
            item-text="description"
            item-value="name"
            v-model="criteriaState.govFunction"
            @change="searchByCriteria"
          ></v-select>
        </v-col>
      </v-row>
      </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :mergeCriteria="false"
        toolbarTitle="Department"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsertItem"
        :defaultSortBy="sortBy"
      >
        <template v-slot:[`editedItem`]="{}">
          <DepartmentsEditedItem :baseCrudKey="baseCrudKey" />
        </template>
      </CRUDList>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import DepartmentsEditedItem from './DepartmentsEditedItem.vue';

const getHeaders = () => {
  const headers = [
    { text: 'Department', value: 'dept' },
    { text: 'Description', value: 'description' },
    { text: 'Function', value: 'govFunctionDesc' },
    { text: 'Resources', value: 'resources' },
  ];
  return headers;
};

export default {
  name: 'DepartmentList',
  components: {
    CRUDList,
    CRUDCriteria,
    DepartmentsEditedItem,
  },
  data: () => ({
    baseCrudKey: 'warDepartment',
    headers: [],
    sortBy: [
      {
        column: 'dept',
        direction: 'asc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders(this.canDelete);
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'index',
    ]),
    ...mapState({
      govFunction(state) {
        let values = state.enums.WarGovFunctionEnum || [];
        values = values.map((item, index) => ({
          ...item,
          description: `${index}   ${item.description}`,
        }));
        values = [{
          description: '',
          name: null,
        }, ...values];
        return values;
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapActions('war/department', [
      'loadDepartments',
      'upsrtDepartment',
    ]),
    extractDescription(item) {
      const govFunName = item && item.dept && item.govFunction;
      let govFunctionDescription = '';
      const govFunArr = this.govFunction || [];
      govFunctionDescription = (govFunArr.find((g) => g.name === govFunName) || {}).description;
      return govFunctionDescription;
    },
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadDepartments({
        criteria,
        baseCrudKey,
        postProcessDeparments: (dataItems) => dataItems.map((dataItem) => ({
          ...dataItem,
          govFunctionDesc: this.extractDescription(dataItem),
        })),
      });
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    upsertItem(itemData) {
      const { baseCrudKey } = this;
      const item = this.saveableEdits(itemData);
      if (!this.isNewItem()) {
        if (!window.confirm('This will affect to all associated Transactions, are you sure!')) {
          return false;
        }
      }
      this.upsrtDepartment({
        item,
        baseCrudKey,
        postProcessDeparments: (dataItems) => dataItems.map((dataItem) => ({
          ...dataItem,
          govFunctionDesc: this.extractDescription(dataItem),
        })),
      });
      return true;
    },
    saveableEdits(item) {
      const updatedItem = { ...item };
      delete updatedItem.govFunctionDesc;
      return updatedItem;
    },
  },
};

</script>
