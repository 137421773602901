<template>
   <v-container>
    <v-row>
      <v-col cols="4">
        <v-text-field
          :autofocus="true"
          v-model="editedItem.dept"
          label="Department"
          :rules="[lengthValidation, checkDuplicateValues, ...reqdSelectValidation]"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="editedItem.description"
          label="Description">
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="editedItem.govFunction"
          item-text="description"
          item-value="name"
          label="Government Function"
          :items="govFunction"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          rows="3"
          row-height="25"
          v-model="editedItem.resources"
          label="Resources">
        </v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { checkDuplicate, validateInputLength } from '../../../util/shared/tmc-global';

export default {
  name: 'DepartmentsEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'department-dialog',
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'index',
    ]),
    ...mapState({
      departmentList: (state) => state.base.crud.items.warDepartment,
      govFunction(state) {
        let values = state.enums.WarGovFunctionEnum || [];
        values = values.map((item, index) => ({
          ...item,
          description: `${index}   ${item.description}`,
        }));
        values = [{
          description: '',
          name: null,
        }, ...values];
        return values;
      },
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    changes() {
      return this.editedItem.changes || [];
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    checkDuplicateValues() {
      return this.checkDuplicate({
        stateArr: this.departmentList,
        fieldName: 'dept',
        val: this.editedItem,
        isNewItem: this.isNewItem,
      });
    },
    lengthValidation() {
      return validateInputLength({
        fieldName: 'dept',
        label: 'Department',
        val: this.editedItem,
        requiredLength: 4,
      });
    },
  },
  methods: {
    checkDuplicate,
  },

};
</script>
